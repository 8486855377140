import { SignaturePosition } from 'src/app/shared/utils/memos.constant';

export const MEMO_NUMBER_LIST = [
  {
    name: 'MEMOS.AUTO-GENERATED',
    id: false,
  },
  {
    name: 'MEMOS.CUSTOM-MEMO-NUMBER',
    id: true,
  },
];

export const LOA_SIGN_USER_CHOICES = [
  { value: 'user', label: 'user' },
  { value: 'none user', label: 'non-user' },
];

export const LOA_SIGN_NONE_USER_PLACEHOLDER = {
  name: 'Contact Name',
  job_position: 'Job Position',
  email: 'Email Address',
  phone_number: 'Phone Number',
};

export const PRE_POSITIONS: Array<SignaturePosition> = [
  {
    sequence: 0,
    name: 'date',
    positions: null,
    type: 'date',
  },
  {
    sequence: 1,
    name: 'memo number',
    positions: null,
    type: 'memo_number',
  },
  {
    sequence: 2,
    name: 'watermark',
    positions: null,
    type: 'watermark',
  },
];

export const IS_REQUIRE = [
  {
    label: 'Require',
    label_th: 'ต้องการ',
    value: true,
  },
  {
    label: 'Not require',
    label_th: 'ไม่ต้องการ',
    value: false,
  },
];

export const SCHEDULE_DAY = [
  {
    label: '5',
    value: '5',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '15',
    value: '15',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '30',
    value: '30',
  },
];
export const SCHEDULE_FULL_DAY = dropdownFullDay();
export const FREQUENCY = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Onetime',
    value: 'one_time',
  },
];
export const VAT = [
  {
    label: 'VAT 7%',
    value: '7%',
  },
  {
    label: 'Non VAT',
    value: 'non_vat',
  },
];
export const getDefaultImage = () => [
  {
    id: null,
    sequence: 1,
    name: 'ภาพรวมห้องน้ำ',
    images: '',
    file_name: '',
  },
  {
    id: null,
    sequence: 2,
    name: 'ภาพรวมห้องนอน',
    images: '',
    file_name: '',
  },
  {
    id: null,
    sequence: 3,
    name: 'ภาพรวมห้องครัว',
    images: '',
    file_name: '',
  },
  {
    id: null,
    sequence: 4,
    name: 'ภาพรวมตู้เสื้อผ้าและทีวี',
    images: '',
    file_name: '',
  },
  {
    id: null,
    sequence: 5,
    name: 'ภาพรวมระเบียง',
    images: '',
    file_name: '',
  },
];

export const TRANSFER_RECEIVE_VALUE = [
  {
    keys: 'location',
    value: null,
  },
  {
    keys: 'property',
    value: null,
  },
  {
    keys: 'building',
    value: null,
  },
  {
    keys: 'floor',
    value: [],
  },
  {
    keys: 'office',
    value: [],
  },
  {
    keys: 'vr_electric_date',
    value: null,
  },
  {
    keys: 'vr_water_date',
    value: null,
  },
  {
    keys: 'transfer_date',
    value: null,
  },
  {
    keys: 'vr_electric_value',
    value: 0,
  },
  {
    keys: 'vr_water_value',
    value: 0,
  },
  {
    keys: 'vr_electric_receive_value',
    value: 0,
  },
  {
    keys: 'vr_water_receive_value',
    value: 0,
  },
  {
    keys: 'vr_electric',
    value: '',
  },
  {
    keys: 'vr_water',
    value: '',
  },
  {
    keys: 'incomplete_price',
    value: [],
  },
  {
    keys: 'start_date',
    value: null,
  },
  {
    keys: 'end_date',
    value: null,
  },
  {
    keys: 'expense_details',
    value: [],
  },
];
export const CONTRACT_REFERENCE_VALUE = [
  {
    keys: 'reservation_discount',
    value: null,
  },
  {
    keys: 'agreement_contract_notes',
    value: [],
  },
  {
    keys: 'subject_ref_reservation_contract',
    value: null,
  },
  {
    keys: 'subject',
    value: null,
  },
  {
    keys: 'person_type',
    value: 'individual_person',
  },
  {
    keys: 'reserve_person',
    value: null,
  },
  {
    keys: 'location',
    value: null,
  },
  {
    keys: 'property',
    value: null,
  },
  {
    keys: 'building',
    value: null,
  },
  {
    keys: 'floor',
    value: [],
  },
  {
    keys: 'office',
    value: [],
  },
  {
    keys: 'start_date',
    value: null,
  },
  {
    keys: 'end_date',
    value: null,
  },
  {
    keys: 'expense_details',
    value: [],
  },
  {
    keys: 'overview_images',
    value: getDefaultImage(),
  },
  {
    keys: 'previous_start_date',
    value: null,
  },
  {
    keys: 'previous_end_date',
    value: null,
  },
  {
    keys: 'advance_payment',
    value: 0,
  },
  {
    keys: 'security_deposit',
    value: 0,
  },
  {
    keys: 'current_start_date',
    value: null,
  },
  {
    keys: 'current_end_date',
    value: null,
  },
  {
    keys: 'date_difference',
    value: null,
  },
];
export const RENT_APPLIANCE_VALUE = [
  ...CONTRACT_REFERENCE_VALUE,
  {
    keys: 'grand_total',
    value: 0,
  },
  {
    keys: 'total_vat',
    value: 0,
  },
];

export function dropdownFullDay(): Array<{
  label: string;
  value: string;
}> {
  const fullDay = [];
  for (let day = 1; day <= 31; day++) {
    fullDay.push({
      label: day.toString(),
      value: day.toString(),
    });
  }
  return fullDay;
}

export const REQUEST_ITEMS = [
  {
    value: 'change_lessee',
    label: 'Change Lessee',
    label_th: 'ขอเปลี่ยนชื่อผู้เช่า',
  },
  {
    label_th: 'ขอเปลี่ยนห้องพัก',
    label: 'Change Room',
    value: 'change_room',
  },
  {
    value: 'early_cancellation',
    label: 'Terminate Contract',
    label_th: 'ยกเลิกสัญญาเช่าก่อนครบกำหนด',
  },
  {
    label_th: 'ต่อสัญญา',
    label: 'Renew Contract',
    value: 'renew',
  },
  {
    label_th: 'ไม่ต่อสัญญา',
    label: 'Not Renew Contract',
    value: 'not_renew',
  },
  {
    label_th: 'ขอเช่าอุปกรณ์เพิ่ม',
    label: 'Rent Appliances',
    value: 'request_appliance',
  },
  {
    label_th: 'ขอปรับปรุงพื้นที่เช่า',
    label: 'Rental space renovation',
    value: 'improvement_deposit',
  },
];

export const DOCUMENT_PERMISSION = [
  {
    label_th: 'ต่ออายุสัญญา',
    label: 'Renewal',
    value: 'renewal',
  },
  {
    label_th: 'ไม่ต่ออายุสัญญา',
    label: 'Non Renewal',
    value: 'non_renewal',
  },
  {
    label_th: 'ยกเลิกสัญญาเช่าก่อนครบกำหนด',
    label: 'Terminate contract early',
    value: 'early_cancellation',
  },
  {
    label_th: 'ขอเปลี่ยนห้องพัก',
    label: 'Change Room',
    value: 'change_room',
  },
  {
    label_th: 'ขอเปลี่ยนชื่อผู้เช่า',
    label: 'Change Lessee',
    value: 'change_lessee',
  },
  {
    label_th: 'แจ้งปรับสถานะห้อง',
    label: 'Change Room Status',
    value: 'change_status',
  },
  {
    label_th: 'ขอเช่าอุปกรณ์เพิ่ม',
    label: 'Rent Appliance',
    value: 'request_appliance',
  },
  {
    label_th: 'ขอปรับปรุงพื้นที่เช่า',
    label: 'Rental space renovation',
    value: 'improvement_deposit',
  },
];

export const YEAR_ITEMS = [
  {
    label: '6 เดือน',
    label_en: '6 Month',
    value: '6',
  },
  {
    label: '1 ปี',
    label_en: '1',
    value: '1',
  },
  {
    label: '2 ปี',
    label_en: '2',
    value: '2',
  },
];

export const REASON_NON_RENEWAL = [
  {
    label: 'เปลี่ยนที่อยู่อาศัยกลับภูมิลำเนา',
    label_en: 'Move back to hometown',
    value: 'move_back_to_hometown',
  },
  {
    label: 'เปลี่ยนสถานที่ทำงาน',
    label_en: 'Change of workplace',
    value: 'change_of_workplace',
  },
  {
    label: 'เปลี่ยนที่อยู่อาศัยที่ขนาดใหญ่ขึ้น',
    label_en: 'Move to a bigger place',
    value: 'move_to_a_bigger_place',
  },
  {
    label: 'เปลี่ยนที่อยู่อาศัยเพื่อค่าใช้จ่ายลดลง',
    label_en: 'Move to reduce expenses',
    value: 'move_to_reduce_expenses',
  },
  {
    label:
      'เปลี่ยนที่อยู่อาศัยเพื่อได้เพื่อนบ้านผู้ร่วมอาศัยที่ดีขึ้น',
    label_en: 'Move for a better neighbors',
    value: 'move_for_a_better_neighbors',
  },
  {
    label: 'อื่นๆ',
    label_en: 'Others',
    value: 'other',
  },
];

export const ROOM_CHANGE = [
  {
    label: 'ลูกค้าค้างชำระ และไม่สามารถติดต่อลูกค้าได้',
    label_en:
      'The lessee has an overdue payment and cannot be contacted.',
    value: 'overdue_payment',
  },
  {
    label: 'ลูกค้าผิดเงื่อนไขสัญญา',
    label_en: 'The lessee has breached the terms and conditions.',
    value: 'breach_contract',
  },
  {
    label: 'ขอจัดทำเอกสารย้อนหลัง',
    label_en: 'Request to create document proactively.',
    value: 'create_proactively',
  },
];
export const RENT_APPLIANCES = [
  {
    label: 'หาย',
    label_en: 'Lost',
    value: 'lost',
  },
  {
    label: 'ชำรุด',
    label_en: 'Damaged',
    value: 'damaged',
  },
  {
    label: 'ซื้อเพิ่ม',
    label_en: 'Buy More',
    value: 'buy_more',
  },
  {
    label: 'อื่นๆ',
    label_en: 'Other',
    value: 'other',
  },
];

export const ATTACHMENT_LANDING = [
  {
    id: null,
    name: 'FORM.Copy of Identification Card',
    images: '',
    file_name: '',
  },
  {
    id: null,
    name: 'FORM.Upload a bank book page',
    images: '',
    file_name: '',
  },
];

export const CHANGE_ROOM_REASON = [
  {
    label: '1. ต้องการเปลี่ยนขนาดห้อง',
    label_en: '1. Want to change room size',
    value: 'want_to_change_room_size',
    tab_indent: false,
    disabled: true,
  },
  {
    label: '1.1 เปลี่ยนจากห้องเล็กเป็นห้องใหญ่',
    label_en: '1.1 Change from a small room to a large room.',
    value: 'change_room_size_larger',
    tab_indent: true,
  },
  {
    label: '1.2 เปลี่ยนจากห้องใหญ่เป็นห้องเล็ก',
    label_en: '1.2 Change from a large room to a small room.',
    value: 'change_room_size_smaller',
    tab_indent: true,
  },
  {
    label: '2. ต้องการเปลี่ยนเป็นห้องที่ใหม่กว่าเดิม',
    label_en: '2. Want to change to a newer room.',
    value: 'change_newer_room',
    tab_indent: false,
  },
  {
    label: '3. ต้องการเปลี่ยนทิศทาง / ฮวงจุ้ย หรือใกล้ทางเข้า-ออก',
    label_en:
      '3. Want to change direction / orientation or proximity to entrance-exit.',
    value: 'change_direction',
    tab_indent: false,
  },
  {
    label:
      '4. มีสิ่งรบกวนจากห้องข้างเคียง เช่น เสียงดัง กลิ่นบุหรี่ กลิ่นทำอาหาร',
    label_en:
      '4. Disturbances from neighboring rooms, such as loud noise, cigarette smell, cooking odors.',
    value: 'disturbances_from_neighboring',
    tab_indent: false,
  },
  {
    label:
      '5. พบปัญหาบางประการจากห้องที่เช่า ซึ่งไม่สามารถแก้ไขให้เป็นปกติได้',
    label_en:
      '5. Encountered some issues with the rented room that cannot be fixed to normalcy.',
    value: 'water_system_problems',
    tab_indent: false,
    disabled: true,
  },
  {
    label: '5.1 ระบบน้ำมีปัญหา เช่น น้ำรั่ว ซึม ท่อแตก สุขภัณฑ์ชำรุด',
    label_en:
      '5.1 Water system problems, such as leaks, seepage, broken pipes, damaged fixtures.',
    value: 'room_walls_problems',
    tab_indent: true,
  },
  {
    label:
      '5.2  ผหนังห้อง ผนังห้องน้ำแตก ร้าว หรือพื้นกระเบื้องมีปัญหา',
    label_en:
      '5.2 Room walls, bathroom walls, or floor tiles have problems.',
    value: 'equipment_problems',
    tab_indent: true,
  },
  {
    label:
      '5.3 อุปกรณ์ หรือเฟอร์นิเจอร์มีปัญหา ซึ่งไม่สามารถแก้ไขได้',
    label_en:
      '5.3 Equipment or furniture has problems that cannot be fixed.',
    value: 'equipment_problems',
    tab_indent: true,
  },
  {
    label: '5.4 ระบบไฟฟ้าภายในห้องเสียหายหนัก ไม่สามารถใช้งานได้',
    label_en: '5.4 Internal electrical system is damaged, unusable.',
    value: 'internal_electrical_problems',
    tab_indent: true,
  },
  {
    label: '5.5 อื่นๆ (ระบุ)',
    label_en: '5.5 Others (specify)',
    value: 'other',
    tab_indent: true,
  },
];

export enum DefaultNoteLabelRequestForm {
  // eslint-disable-next-line max-len
  LESSEE_RENEWAL_REQUEST = 'Once the lessee requests for the renewal of the lease agreement, it shall be deemed that the request from the lessee as part of the lease agreement. If the lease term is completed, there is a right to receive a refund of the security deposit.',
  // eslint-disable-next-line max-len
  LESSEE_RENEWAL_REQUEST_THAI = 'เมื่อผู้เช่าแจ้งความประสงค์ต่ออายุสัญญากับผู้ให้เช่าแล้ว ให้ถือว่าแบบแจ้งความประสงค์ขอต่ออายุสัญญาของผู้เช่า มีผลทางกฎหมายเป็นสัญญาเช่าที่ต่ออายุสัญญาออกไปโดยสมบรูณ์ หากอยู่ครบสัญญาเช่า มีสิทธิ์ได้รับเงินประกันคืน',
}

export enum PersonType {
  INDIVIDUAL_PERSON = 'individual_person',
  COMPANY_PERSON = 'company',
}

export enum CardType {
  CITIZEN = 'citizen_id',
  PASSPORT = 'passport_id',
}

export enum CCDefaultGroup {
  AR_ACCOUNT_APT = 'กลุ่มบัญชี AR (APT)',
  AR_SUPPORT_APT = 'กลุ่มสนับสนุน AR (APT)',
  OPERATIONS_APT = 'กลุ่มปฏิบัติการ (APT)',
  MARKETING_APT = 'กลุ่มการตลาด (APT)',
  FINANCE_APT = 'ฝ่ายการเงิน (APT)',
  AR_ACCOUNT_CHOD_BIZ = 'กลุ่มบัญชี AR (CHOD BIZ)',
  AR_SUPPORT_CHOD_BIZ = 'กลุ่มสนับสนุน AR (CHOD BIZ)',
  OPERATIONS_CHOD_BIZ = 'กลุ่มปฏิบัติการ (CHOD BIZ)',
  MARKETING_CHOD_BIZ = 'กลุ่มการตลาด (CHOD BIZ)',
  LEGAL_CHOD_BIZ = 'กลุ่มสำนักกฏหมาย (CHOD BIZ)',
}

export const VEHECLE_TYPE = [
  {
    label_th: 'รถจักรยานยนต์',
    label: 'Motorcycle',
    value: 'motorcycle',
  },
  {
    label_th: 'รถยนต์',
    label: 'Car',
    value: 'car',
  },
  {
    label_th: 'รถบรรทุก',
    label: 'Truck',
    value: 'truck',
  },
];

export const DUE_DATE = () => {
  const dueDate = [];
  for (let day = 5; day <= 30; day += 5) {
    dueDate.push(day);
  }
  return dueDate;
};

export enum MemoType {
  Internal = 'internal',
  External = 'external',
  PurchaseRequest = 'purchase_request',
  Reservation = 'reservation',
  AgreementContract = 'agreement_contract',
  TransferDocument = 'transfer_document',
  ReceiveDocument = 'receive_document',
  RentAppliance = 'rent_appliance',
  ContractCarPark = 'contract_car_park',
  LoiReservation = 'loi',
  ChodBizContract = 'contract_chod_biz',
  ReceiveChodBiz = 'receive_chod_biz',
  SpaceRenovation = 'space_renovation',
}

export enum RefKeyRequestForm {
  PreviousStartDate = 'previous_start_date',
  PreviousEndDate = 'previous_end_date',
  AdvancePayment = 'advance_payment',
  SecurityDeposit = 'security_deposit',
  CurrentStartDate = 'current_start_date',
  CurrentEndDate = 'current_end_date',
}

export enum RequestFormMemos {
  ChangeRoom = 'change_room',
  Renewal = 'renewal',
  ChangeLessee = 'change_lessee',
}

export const MEMOS_CAN_UPLOAD_PDF_ATTACHMENT: string[] = [
  MemoType.Internal,
  MemoType.External,
  MemoType.PurchaseRequest,
  MemoType.Reservation,
  MemoType.AgreementContract,
  MemoType.TransferDocument,
  MemoType.ReceiveDocument,
  MemoType.RentAppliance,
  MemoType.ContractCarPark,
  MemoType.LoiReservation,
  MemoType.ChodBizContract,
  MemoType.ReceiveChodBiz,
  MemoType.SpaceRenovation,
];

export const CONTRACT_CAN_REF_MEMOS: string[] = [
  MemoType.AgreementContract,
  MemoType.TransferDocument,
  MemoType.ReceiveDocument,
  MemoType.RentAppliance,
  MemoType.ContractCarPark,
  MemoType.ChodBizContract,
  MemoType.ReceiveChodBiz,
  MemoType.SpaceRenovation,
];
export const CONTRACT_MEMOS_INPUT_PERSON: string[] = [
  MemoType.AgreementContract,
  MemoType.TransferDocument,
  MemoType.ReceiveDocument,
  MemoType.ContractCarPark,
  MemoType.ChodBizContract,
  MemoType.ReceiveChodBiz,
  MemoType.SpaceRenovation,
];

export const MEMO_TYPE_CHOD_BIZ: string[] = [
  MemoType.LoiReservation,
  MemoType.ChodBizContract,
];

export const CONTRACT_ALL_CUSTOMIZE_MEMOS: string[] = [
  MemoType.Reservation,
  MemoType.AgreementContract,
  MemoType.TransferDocument,
  MemoType.ReceiveDocument,
  MemoType.RentAppliance,
  MemoType.ContractCarPark,
  ...MEMO_TYPE_CHOD_BIZ,
  MemoType.ReceiveChodBiz,
  MemoType.SpaceRenovation,
];

export const CONTRACT_MEMOS_WITH_RESERVATION: string[] = [
  MemoType.Reservation,
  MemoType.AgreementContract,
  MemoType.TransferDocument,
  MemoType.ReceiveDocument,
  MemoType.RentAppliance,
  MemoType.ContractCarPark,
  MemoType.ReceiveChodBiz,
];

export const CONTRACT_MEMOS_CC: string[] = [
  MemoType.Reservation,
  MemoType.AgreementContract,
  MemoType.TransferDocument,
  MemoType.ReceiveDocument,
  MemoType.ContractCarPark,
  MemoType.ReceiveChodBiz,
  MemoType.ChodBizContract,
  MemoType.LoiReservation,
];

export const TRANSFER_RECEIVE_MEMOS: string[] = [
  MemoType.TransferDocument,
  MemoType.ReceiveDocument,
  MemoType.ReceiveChodBiz,
];

export const CONTRACT_AGREEMENT_CAR_PARK: string[] = [
  MemoType.AgreementContract,
  MemoType.ContractCarPark,
];

export const MEMO_CAN_UPLOAD_EVIDENCE: string[] = [
  MemoType.AgreementContract,
  MemoType.ContractCarPark,
  MemoType.ChodBizContract,
];

export const REF_KEYS_REQUEST_FORM: string[] = [
  RefKeyRequestForm.PreviousStartDate,
  RefKeyRequestForm.PreviousEndDate,
  RefKeyRequestForm.AdvancePayment,
  RefKeyRequestForm.SecurityDeposit,
  RefKeyRequestForm.CurrentStartDate,
  RefKeyRequestForm.CurrentEndDate,
];

export const MEMOS_HAS_PAYMENT_DETAILS: string[] = [
  MemoType.AgreementContract,
  MemoType.Reservation,
  MemoType.ReceiveDocument,
  MemoType.RentAppliance,
  MemoType.ContractCarPark,
  MemoType.LoiReservation,
  MemoType.ChodBizContract,
  MemoType.ReceiveChodBiz,
  MemoType.SpaceRenovation,
];

export const MEMOS_USE_RATIO: string[] = [
  MemoType.Reservation,
  MemoType.AgreementContract,
  MemoType.ContractCarPark,
];

export const REQUEST_FORM_MEMOS_TYPE: string[] = [
  RequestFormMemos.ChangeRoom,
  RequestFormMemos.Renewal,
  RequestFormMemos.ChangeLessee,
];

export const MEMOS_DISABLED_END_DATE: string[] = [
  MemoType.Reservation,
  MemoType.AgreementContract,
  MemoType.ContractCarPark,
];

export const MEMOS_USE_OTP: string[] = [
  MemoType.Reservation,
  MemoType.AgreementContract,
  MemoType.ContractCarPark,
];

export const MEMOS_TYPE_DEFAULT_CONTRACT_DATE_TODAY: string[] = [
  MemoType.Reservation,
  MemoType.AgreementContract,
  MemoType.TransferDocument,
  MemoType.ContractCarPark,
  MemoType.LoiReservation,
  MemoType.ChodBizContract,
  MemoType.SpaceRenovation,
];

export const MEMOS_TYPE_CUSTOM_PARAMS_LOA: string[] = [
  MemoType.Reservation,
  MemoType.AgreementContract,
  MemoType.TransferDocument,
  MemoType.ReceiveDocument,
  MemoType.ContractCarPark,
  MemoType.LoiReservation,
  MemoType.ChodBizContract,
  MemoType.ReceiveChodBiz,
  MemoType.SpaceRenovation,
];

export const MEMOS_TYPE_MANAGE_LOA_ADMIN: string[] = [
  MemoType.Reservation,
  MemoType.AgreementContract,
  MemoType.TransferDocument,
  MemoType.ReceiveDocument,
  MemoType.LoiReservation,
  MemoType.ChodBizContract,
  MemoType.ReceiveChodBiz,
  MemoType.SpaceRenovation,
];

export const MEMOS_TYPE_USE_DATA_TOTAL: string[] = [
  MemoType.RentAppliance,
  MemoType.Reservation,
  MemoType.AgreementContract,
  MemoType.ContractCarPark,
  MemoType.ChodBizContract,
  MemoType.LoiReservation,
];

export const MEMOS_HIERARCHY_RELATED: string[] = [
  MemoType.ChodBizContract,
];

export const MEMOS_TYPE_CAN_TERMINATE_DOCUMENT: string[] = [
  MemoType.Reservation,
  MemoType.LoiReservation,
];
export const MEMOS_UPLOAD_LOCATION_MAP: string[] = [
  MemoType.ChodBizContract,
];

export const MEMOS_UPLOAD_LOCATION_MAP_MULTIPLE: string[] = [
  MemoType.SpaceRenovation,
];

export const MEMOS_TYPE_RECEIVE = [
  MemoType.ReceiveDocument,
  MemoType.ReceiveChodBiz,
];

export const MEMOS_TYPE_CAN_UPLOAD_COMPANY_STAMP: string[] = [
  MemoType.AgreementContract,
  MemoType.ContractCarPark,
  MemoType.ChodBizContract,
  MemoType.LoiReservation,
];

export const DOWNLOAD_CSV_MEMOS = [
  {
    translateText: 'LOG.DOWNLOAD-RESERVATION-CONTRACT',
    modalText: 'MEMOS.CONFIRM-DOWNLOAD-RESERVATION-CONTRACT',
    type: 'reservation',
  },
  {
    translateText: 'LOG.DOWNLOAD-CONTRACT',
    modalText: 'MEMOS.CONFIRM-DOWNLOAD-CONTRACT',
    type: 'agreement_contract',
  },
  {
    translateText: 'LOG.DOWNLOAD-CONTRACT-CAR-PARK',
    modalText: 'MEMOS.CONFIRM-DOWNLOAD-CONTRACT-CAR-PARK',
    type: 'contract_car_park',
  },
  {
    translateText: 'LOG.DOWNLOAD-TRANSFER',
    modalText: 'MEMOS.CONFIRM-DOWNLOAD-TRANSFER',
    type: 'transfer_document',
  },
  {
    translateText: 'LOG.DOWNLOAD-RECEIEVE',
    modalText: 'MEMOS.CONFIRM-DOWNLOAD-RECEIVE',
    type: 'receive_document',
  },
  {
    translateText: 'LOG.DOWNLOAD-PRODUCT-LIST',
    modalText: 'MEMOS.CONFIRM-DOWNLOAD-PRODUCT-LIST',
    type: 'product_list',
  },
  {
    translateText: 'LOG.DOWNLOAD-E-STAMP',
    modalText: 'MEMOS.CONFIRM-DOWNLOAD-E-STAMP',
    type: 'e_stamp',
  },
  {
    translateText: 'LOG.DOWNLOAD-LOI',
    modalText: 'MEMOS.CONFIRM-DOWNLOAD-LOI',
    type: 'loi',
  },
  {
    translateText: 'LOG.DOWNLOAD-CONTRACT-CHODBIZ',
    modalText: 'MEMOS.CONFIRM-DOWNLOAD-CHODBIZ-CONTRACT',
    type: 'chodbiz_contract',
  },
  {
    translateText: 'LOG.DOWNLOAD-SPACE-RENOVATION',
    modalText: 'MEMOS.CONFIRM-DOWNLOAD-SPACE-RENOVATION',
    type: 'space_renovation',
  },
  {
    translateText: 'LOG.DOWNLOAD-RENT-APPLIANCE',
    modalText: 'MEMOS.CONFIRM-DOWNLOAD-RENT-APPLIANCE',
    type: 'rent_appliance',
  },
];

export enum PresetPurposeCode {
  Rent = 'RENT',
  Rental = 'RENTAL',
  Service = 'SERVICE',
  ServiceFee = 'SERVICE FEE',
  CommonService = 'COMMON SERVICE',
  CommonService1 = 'COMMON SERVICE 1',
  CommonServiceAndEquipment = 'COMMON SERVICE AND EQUIPMENT',
  Deposit = 'DEPOSIT',
  Deposit2 = 'DEPOSIT 2',
  AdvanceRental = 'ADVANCE RENTAL',
  SecurityDeposit = 'SECURITY DEPOSIT',
  SecurityDepositService = 'SECURITY DEPOSIT SERVICE',
  SecurityDepositCommonService = 'SECURITY DEPOSIT COMMON SERVIC',
  ManagementElectricAndWater = 'MANAGEMENT ELECTRIC AND WATER',
  StampDuty = 'STAMP DUGY',
  LandAndBuildingTax = 'LAND AND BUILDING TAX',
}

export const PURPOSE_CODES_DISABLED_FIELD: string[] = [
  PresetPurposeCode.Rent,
  PresetPurposeCode.CommonServiceAndEquipment,
  PresetPurposeCode.Deposit2,
  PresetPurposeCode.AdvanceRental,
];
export const RENT_PURPOSE_CODES: string[] = [
  PresetPurposeCode.Rent,
  PresetPurposeCode.Rental,
];
export const SERVICE_FEE_PURPOSE_CODES: string[] = [
  PresetPurposeCode.Service,
  PresetPurposeCode.ServiceFee,
];
export const COMMON_SERVICE_PURPOSE_CODES: string[] = [
  PresetPurposeCode.CommonService,
  PresetPurposeCode.CommonService1,
];

export const INCLUDED_BILLING_TYPE_ONE_TIME: string[] = ['DP', 'LO'];
export const INCLUDED_BILLING_TYPE_MONTHLY: string[] = [
  'LM',
  'RM',
  'LC',
];

export const MEMOS_LIST = [
  {
    label: 'แบบขออนุมัติภายใน',
    label_en: 'Internal',
    value: 'internal',
  },
  {
    label: 'แบบขออนุมัติภายนอก',
    label_en: 'External',
    value: 'external',
  },
  {
    label: 'แบบขออนุมัติการซื้อ',
    label_en: 'Purchase Request',
    value: 'purchase_request',
  },
  {
    label: 'ใบจอง',
    label_en: 'Reservation Contract',
    value: 'reservation',
  },
  {
    label: 'สัญญา',
    label_en: 'Contract',
    value: 'agreement_contract',
  },
  {
    label: 'ใบส่งมอบ',
    label_en: 'Transfer Room Document',
    value: 'transfer_document',
  },
  {
    label: 'ใบรับมอบ',
    label_en: 'Receive Room Document',
    value: 'receive_document',
  },
  {
    label: 'ใบขอเช่าอุปกรณ์เพิ่ม',
    label_en: 'Rent Appliance',
    value: 'rent_appliance',
  },
  {
    label: 'สัญญาที่จอดรถ',
    label_en: 'Parking Contract',
    value: 'contract_car_park',
  },
  {
    label: 'LOI (จอง)',
    label_en: 'LOI (Reservation)',
    value: 'loi',
  },
  {
    label: 'สัญญาโชติบิซ',
    label_en: 'Chodbiz Contract',
    value: 'contract_chod_biz',
  },
  {
    label: 'ใบรับมอบโชติบิซ',
    label_en: 'Receive Document Chodbiz',
    value: MemoType.ReceiveChodBiz,
  },
  {
    label: 'ใบขอปรับปรุงพื้นที่',
    label_en: 'Space Renovation',
    value: MemoType.SpaceRenovation,
  },
];

export const RESERVATION_CC_DEFAULT = [
  CCDefaultGroup.AR_ACCOUNT_APT,
  CCDefaultGroup.AR_SUPPORT_APT,
];
export const AGREEMENT_CONTRACT_CC_DEFAULT = [
  CCDefaultGroup.AR_ACCOUNT_APT,
  CCDefaultGroup.AR_SUPPORT_APT,
  CCDefaultGroup.OPERATIONS_APT,
];
export const TRANSFER_DOC_CHODBIZ_CONTRACT_CC_DEFAULT = [
  CCDefaultGroup.AR_SUPPORT_CHOD_BIZ,
  CCDefaultGroup.OPERATIONS_CHOD_BIZ,
  CCDefaultGroup.AR_ACCOUNT_CHOD_BIZ,
  CCDefaultGroup.LEGAL_CHOD_BIZ,
  CCDefaultGroup.MARKETING_CHOD_BIZ,
];
export const TRANSFER_DOC_APT_CONTRACT_CC_DEFAULT = [
  CCDefaultGroup.AR_SUPPORT_APT,
  CCDefaultGroup.OPERATIONS_APT,
];
export const RECEIVE_DOC_APT_CONTRACT_CC_DEFAULT = [
  CCDefaultGroup.AR_SUPPORT_APT,
  CCDefaultGroup.OPERATIONS_APT,
  CCDefaultGroup.AR_ACCOUNT_APT,
];
export const RECEIVE_CHODBIZ_CONTRACT_CC_DEFAULT = [
  CCDefaultGroup.AR_SUPPORT_CHOD_BIZ,
  CCDefaultGroup.OPERATIONS_CHOD_BIZ,
  CCDefaultGroup.AR_ACCOUNT_CHOD_BIZ,
  CCDefaultGroup.LEGAL_CHOD_BIZ,
  CCDefaultGroup.MARKETING_CHOD_BIZ,
];
export const CONTRACT_CAR_PARK_CC_DEFAULT = [
  CCDefaultGroup.AR_ACCOUNT_APT,
  CCDefaultGroup.AR_SUPPORT_APT,
  CCDefaultGroup.OPERATIONS_APT,
];
export const CHODBIZ_CONTRACT_CC_DEFAULT = [
  CCDefaultGroup.AR_SUPPORT_CHOD_BIZ,
  CCDefaultGroup.OPERATIONS_CHOD_BIZ,
  CCDefaultGroup.AR_ACCOUNT_CHOD_BIZ,
  CCDefaultGroup.LEGAL_CHOD_BIZ,
  CCDefaultGroup.MARKETING_CHOD_BIZ,
];
export const LOI_CC_DEFAULT = [
  CCDefaultGroup.AR_SUPPORT_CHOD_BIZ,
  CCDefaultGroup.OPERATIONS_CHOD_BIZ,
  CCDefaultGroup.AR_ACCOUNT_CHOD_BIZ,
  CCDefaultGroup.LEGAL_CHOD_BIZ,
  CCDefaultGroup.MARKETING_CHOD_BIZ,
];

export const SPACE_RENOVATION_CC_DEFAULT = [];

export const RENEWAL_SECURITY_DEPOSIT_DETAILS = [
  'IMPROVEMENT DEPOSIT',
  'SECURITY DEPOSIT',
  'SECURITY DEPOSIT COMMON SERVIC',
  'SECURITY DEPOSIT FURNITURE',
  'SECURITY DEPOSIT SERVICE',
];

export const MEMO_TYPE_REQUEST_FORM = [
  ...DOCUMENT_PERMISSION.map((item) => {
    return {
      label: `แจ้งความประสงค์ (${item.label_th})`,
      label_en: item.label,
      value: item.value,
    };
  }),
];
